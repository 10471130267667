import React, { useState } from 'react';
import styled from 'styled-components';

import { ProductInfo, ProductTitleType, MenuTitleType, GA } from '@t/components';
import { IconType } from '@t/styles';
import { isKorean } from '@/util';
import { feGuideUrl, postUrl } from '@/constant';
import { BlockLink, InlineBlockLink } from '@/components/common/Link';
import { Icon } from '@/components/common/Icon';
import createGA from '@/components/common/createGA';

interface SitemapTitleWithSubMenuProps {
  items: ProductInfo[];
  title: ProductTitleType;
  ga: GA;
  isHeader: boolean;
}

interface SitemapTitleProps {
  title: Exclude<MenuTitleType, ProductTitleType>;
  ga: GA;
  isHeader: boolean;
}

const urlMap = {
  'FE Guide': isKorean ? feGuideUrl.KO : feGuideUrl.EN,
  Posts: isKorean ? postUrl.KO : postUrl.EN,
};

const Wrapper = styled.div`
  margin-right: 100px;
  float: left;

  @media (max-width: 1200px) {
    margin-right: 70px;
  }

  @media (max-width: 720px) {
    margin-right: 0;
    cursor: pointer;

    &.opened {
      margin-bottom: 0;
    }

    &.closed {
      margin-bottom: 12px;
      height: 50px;
    }

    &:active {
      -webkit-tap-highlight-color: transparent;
    }
  }
`;

const TitleArea = styled.div`
  height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 720px) {
    height: 48px;

    & .is-header ~ i {
      background-size: 900px;
    }
  }
`;

const Title = styled.div`
  display: inline-block;
  width: 100px;
  margin-bottom: 30px;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1200px) {
    width: 75px;
    margin-bottom: 20px;
  }

  @media (max-width: 720px) {
    width: 100%;
    height: 50px;
    line-height: 50px;
    margin-bottom: 12px;
  }
`;

const MenuTitle = styled.span`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #222;
  white-space: nowrap;

  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 18px;
  }

  @media (max-width: 720px) {
    font-size: 16px;
    line-height: 1;
    font-weight: bold;

    &.is-header {
      font-size: 19px;

      &.opened {
        color: #515ce6;
      }
    }
  }
`;

const Menu = styled.span`
  font-size: 14px;
  line-height: 30px;
  color: #777;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1200px) {
    font-size: 10px;
    line-height: 23px;
  }

  @media (max-width: 720px) {
    font-size: 14px;
    line-height: 2;

    &.is-header {
      font-size: 15px;
    }
  }
`;

const MoreButton = styled(Icon)`
  @media (max-width: 720px) {
    width: 24px;
    height: 24px;
    background-size: 1200px;
  }
`;

const SubMenu = styled.div`
  margin: 15px 0;
  display: flex;
  flex-direction: column;

  @media (max-width: 720px) {
    margin: 0;
    padding-left: 10px;

    &.closed {
      display: none;
    }
  }
`;

export const SitemapTitleWithSubMenu: React.FC<SitemapTitleWithSubMenuProps> = ({
  items,
  title,
  isHeader,
}) => {
  const [className, setClassName] = useState<'opened' | 'closed'>('closed');
  const ga = createGA('Main - Sitemap', 'click', 'Sitemap');

  return (
    <Wrapper className={className}>
      <TitleArea onClick={() => setClassName(className === 'opened' ? 'closed' : 'opened')}>
        <MenuTitle className={`${className} ${isHeader ? 'is-header' : ''}`}>{title}</MenuTitle>
        <MoreButton
          iconType={
            `${isHeader ? 'HEADER_' : ''}SITEMAP_${className.toUpperCase()}_ICON` as IconType
          }
        />
      </TitleArea>
      <SubMenu className={className}>
        {items.map(({ id, name, link }) => {
          const type = title !== 'Tools' ? 'internal' : 'external';
          const url =
            title !== 'Tools' ? `/${id}` : link || `https://github.com/nhn/${id.replace('-', '.')}`;

          return (
            <BlockLink
              key={id}
              type={type}
              url={url}
              ga={{ ...ga, label: `Sitemap - ${name}` }}
              onClick={() => setClassName('closed')}
              testId="sitemap-submenu-list"
            >
              <Menu className={isHeader ? 'is-header' : ''}>{name}</Menu>
            </BlockLink>
          );
        })}
      </SubMenu>
    </Wrapper>
  );
};

export const SitemapTitle: React.FC<SitemapTitleProps> = ({ title, isHeader }) => {
  const ga = createGA('Main - Sitemap', 'click', 'Sitemap', title);

  return (
    <Title>
      <InlineBlockLink
        type="internal"
        url={`${urlMap[title]}`}
        ga={{ ...ga, label: `Sitemap - ${title}` }}
        testId="sitemap-list"
      >
        <MenuTitle className={isHeader ? 'is-header' : ''}>{title}</MenuTitle>
      </InlineBlockLink>
    </Title>
  );
};

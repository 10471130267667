import styled from 'styled-components';

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  letter-spacing: 0;
  word-spacing: 0;
`;

export const ListItem = styled.li`
  display: inline-block;
  position: relative;
  font-size: 14px;
  margin: 0;
  height: 100%;
  color: #555;
  text-decoration: none;
  cursor: pointer;
`;

import React from 'react';
import styled from 'styled-components';

import { ProductType, MenuTitleType, ProductInfo, ProductTitleType, GA } from '@t/components';
import { SitemapTitle, SitemapTitleWithSubMenu } from '@/components/main/sitemap/SitemapTitle';

interface SitemapSectionProps {
  items: SitemapInfo;
  ga: GA;
  isHeader?: boolean;
}

interface SitemapInfo {
  application: ProductInfo[];
  component: ProductInfo[];
  tool: ProductInfo[];
}

interface SitemapItem {
  type?: ProductType;
  title: MenuTitleType;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 80px 0;
  margin: 0 auto;
  max-width: 1200px;
  min-width: 850px;
  width: 62.5%;

  @media (max-width: 1200px) {
    padding-top: 50px;
    max-width: 900px;
    min-width: auto;
    width: 75%;
  }

  @media (max-width: 768px) {
    padding-top: 40px;
    max-width: 700px;
    width: 91%;
  }

  @media (max-width: 720px) {
    max-width: 330px;
    width: 89%;
    flex-direction: column;
    padding: 0;
  }
`;

const SitemapLogo = styled.img`
  @media (max-width: 720px) {
    width: 85px;
    height: 14px;
    margin-top: 45px;
    margin-bottom: 28px;
  }
`;

const SitemapArea = styled.div`
  width: 700px;

  @media (max-width: 1200px) {
    width: 500px;
    margin-right: 0;
  }

  @media (max-width: 720px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
  }
`;

const SitemapSection: React.FC<SitemapSectionProps> = ({ items, ga, isHeader = false }) => {
  const sitemapItems: SitemapItem[] = [
    {
      type: 'application',
      title: 'Applications',
    },
    {
      type: 'component',
      title: 'Components',
    },
    {
      type: 'tool',
      title: 'Tools',
    },
    {
      title: 'FE Guide',
    },
    {
      title: 'Posts',
    },
  ];

  return (
    <Wrapper>
      <div className="sitemap-logo">
        <SitemapLogo src="https://uicdn.toast.com/toastui/img/toast-ui-black-logo.png" />
      </div>
      <SitemapArea>
        {sitemapItems.map(({ type, title }) =>
          type ? (
            <SitemapTitleWithSubMenu
              items={items[type]}
              title={title as ProductTitleType}
              ga={{ ...ga, label: `sitemap - ${title}` }}
              isHeader={isHeader}
              key={title}
            />
          ) : (
            <SitemapTitle
              title={title as Exclude<MenuTitleType, ProductTitleType>}
              ga={{ ...ga, label: `sitemap - ${title}` }}
              isHeader={isHeader}
              key={title}
            />
          )
        )}
      </SitemapArea>
    </Wrapper>
  );
};

export default SitemapSection;

import { ApplicationId, ProductInfo } from '@t/components';
import { IconType } from '@t/styles';
import { ENG_WPM, CJK_CPM } from '@/constant';

interface Position {
  x: number;
  y: number;
}

interface ProductSectionDataProps {
  edges: EdgeNode[];
}

interface EdgeNode {
  node: ProductInfo;
}

interface ProductSectionData {
  application: ProductInfo[];
  component: ProductInfo[];
  tool: ProductInfo[];
  references: ProductInfo[];
}

type IENavigator = typeof window.navigator & { userLanguage: string; browserLanguage: string };

export function getScrollPosition(): Position {
  const supportPageOffset = typeof window.pageXOffset !== 'undefined';
  const isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';

  if (supportPageOffset) {
    return { x: window.pageXOffset, y: window.pageYOffset };
  }

  return {
    x: isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
    y: isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop,
  };
}

export function replaceInvalidHtml(html: string, isPost?: boolean): string {
  const result = html
    .replace('<h1>h1</h1>\n<h2>h2</h2>', '')
    .replace('@<version>', '@&ltversion&gt')
    .replace('#<tag>', '#&lttag&gt');

  return isPost ? result.replace(/<\/h1>/g, '</h1><hr />') : result.replace(/<h3>/g, '<hr /><h3>');
}

export function getReadingTime(markdown: string, lang: string): string {
  markdown = markdown
    .replace(/\n/g, ' ')
    .replace(/(#|\*|-|\|)+/g, '')
    .replace(/(```.*? |<\/?img.*?>)/g, ' ')
    .replace(/ +/g, ' ');

  if (lang === 'ko') {
    markdown = markdown
      .replace(/\[.*?\)/g, '')
      .replace(/<.*?>/g, '')
      .replace(/[^ㄱ-ㅎ|^가-힣|^ㅏ-ㅣ|^\w]+/g, '');

    return `${Math.round(markdown.length / CJK_CPM)} min read`;
  }

  const wordCount = markdown.split(' ').length;

  return `${Math.round(wordCount / ENG_WPM)} min read`;
}

export const isKorean: boolean = (() => {
  if (typeof window !== 'undefined') {
    const navigator = window.navigator as IENavigator;
    const lang = navigator.language || navigator.userLanguage || navigator.browserLanguage;

    return ['ko', 'ko-KR', 'ko-kr'].indexOf(lang) !== -1;
  }

  return false;
})();

export function getSuffix(url: string): string {
  return url.match(/\/([^\\/]+)\/*$/)![1];
}

export function getRepoId(id: string): string {
  return id.split('-')[0] === 'tui' ? id.replace('-', '.') : `toast-ui.${id}`;
}

export function getApplicationIconType(id: ApplicationId): IconType {
  return id.replace('tui-', '').replace('-', '_').toUpperCase().concat('_BI') as IconType;
}

export const createProductSectionData = ({
  edges,
}: ProductSectionDataProps): ProductSectionData => {
  const section: ProductSectionData = {
    application: [],
    component: [],
    tool: [],
    references: [],
  };

  edges.forEach(({ node }) => {
    section[node.type].push(node);
  });

  return section;
};

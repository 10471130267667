require('core-js/modules/es6.set'); // react-dom
require('core-js/modules/es6.map'); // react-dom
require('raf-polyfill'); // react
require('core-js/modules/es6.promise'); // < IE11
require('classlist-polyfill'); // IE9
require('fontsource-noto-sans-kr');

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ReactGA = require('react-ga');
ReactGA.initialize('UA-129962601-1');

exports.shouldUpdateScroll = ({ routerProps: { location } }) => !location.hash;

exports.onRouteUpdate = ({ location }) => {
  ReactGA.pageview(location.pathname);
};

import React from 'react';
import GatsbyLink from 'gatsby-link';
import styled from 'styled-components';
import ReactGA from 'react-ga';

import { GA } from '@t/components';

interface LinkProps {
  type: 'external' | 'internal';
  url: string;
  ga: GA;
  className?: string;
  testId?: string;
  onClick?: () => void;
}

function noop() {
  // do nothing
}

export const Link: React.FC<LinkProps> = ({
  type,
  url,
  ga,
  children,
  className = '',
  testId = '',
  onClick = noop,
}) => {
  if (type === 'external') {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
        data-testid={testId}
        onClick={() => {
          ReactGA.event(ga);
          onClick();
        }}
      >
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink
      to={url}
      className={className}
      onClick={() => {
        ReactGA.event(ga);
        onClick();
      }}
    >
      {children}
    </GatsbyLink>
  );
};

const Logo = styled.div`
  background-image: url('https://uicdn.toast.com/toastui/img/toast-ui-icons-2x-20211012.png');
  background-position: 3.7% 3.7%;
  background-size: 1400px;
  width: 139px;
  height: 22px;
`;

export const LogoLink: React.FC<Pick<LinkProps, 'url' | 'ga' | 'onClick'>> = ({
  url,
  ga,
  children,
  onClick = noop,
}) => (
  <GatsbyLink
    to={url!}
    style={{ display: 'block' }}
    onClick={() => {
      ReactGA.event(ga);
      onClick();
    }}
  >
    <Logo>{children}</Logo>
  </GatsbyLink>
);

export const BlockLink: React.FC<LinkProps> = ({
  type,
  url,
  ga,
  children,
  testId,
  onClick = noop,
}) => {
  if (type === 'external') {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          ReactGA.event(ga);
          onClick();
        }}
        data-testid={testId}
      >
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink
      to={url}
      style={{ display: 'block' }}
      onClick={() => {
        ReactGA.event(ga);
        onClick();
      }}
      data-testid={testId}
    >
      {children}
    </GatsbyLink>
  );
};

export const InlineBlockLink: React.FC<LinkProps> = ({
  type,
  url,
  ga,
  children,
  testId,
  className = '',
  onClick = noop,
}) => {
  if (type === 'external') {
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ display: 'inline-block' }}
        className={className}
        onClick={() => {
          ReactGA.event(ga);
          onClick();
        }}
        data-testid={testId}
      >
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink
      to={url}
      style={{ display: 'inline-block' }}
      className={className}
      onClick={() => {
        ReactGA.event(ga);
        onClick();
      }}
      data-testid={testId}
    >
      {children}
    </GatsbyLink>
  );
};

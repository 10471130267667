import React from 'react';
import styled from 'styled-components';

import { ProductInfo, DropdownMenuInfo } from '@t/components';
import { List, ListItem } from '@/components/common/List';
import { Link } from '@/components/common/Link';
import createGA from '@/components/common/createGA';

interface DropdownMenuProps {
  paddingLeft: number;
  isShow: boolean;
}

interface ItemProps {
  menuWidth: number;
}

const DropdownMenu = styled.div<DropdownMenuProps>`
  position: fixed;
  top: 69px;
  left: 0;
  padding: 21px 0 11px;
  width: calc(100% - ${({ paddingLeft }) => paddingLeft}px);
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  border-top: 1px solid #efefef;

  padding-left: ${({ paddingLeft }) => paddingLeft}px;
  display: ${({ isShow }) => (isShow ? '' : 'none')};
`;

const Menu = styled(List)`
  display: flex;
  flex-wrap: wrap;
  max-width: 1000px;
`;

const Item = styled(ListItem)<ItemProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: ${({ menuWidth }) => `${menuWidth}px`};
  height: 28px;
  margin-bottom: 10px;
  color: #333;

  & a:hover span {
    color: #515ce6;
  }

  & a:hover:after {
    content: none;
  }

  @media (max-width: 1200px) {
    width: ${({ menuWidth }) => `${menuWidth > 130 ? menuWidth - 40 : menuWidth}px`};
  }

  @media (max-width: 768px) {
    width: ${({ menuWidth }) => `${menuWidth > 130 ? menuWidth - 65 : menuWidth}px`};
  }
`;

const MenuName = styled.span`
  padding: 7px;
`;

const Anchor: React.FC<ProductInfo & { hideSubMenu: () => void }> = ({
  id,
  name,
  type,
  link,
  hideSubMenu,
}) => {
  const ga = createGA('Header', 'click', 'header menu', name);

  return type === 'tool' ? (
    <Link
      type="external"
      url={link || `https://github.com/nhn/${id.replace('-', '.')}`}
      ga={ga}
      className="link"
      onClick={hideSubMenu}
      testId={id}
    >
      <MenuName>{name}</MenuName>
    </Link>
  ) : (
    <Link type="internal" className="link" url={`/${id}`} ga={ga} testId={id} onClick={hideSubMenu}>
      <MenuName>{name}</MenuName>
    </Link>
  );
};

const DropdownMenuComponent: React.FC<DropdownMenuInfo> = ({
  type,
  items,
  paddingLeft,
  menuWidth,
  isShow,
  hideSubMenu,
}) => {
  return (
    <DropdownMenu paddingLeft={paddingLeft} isShow={isShow} data-testid={type}>
      <Menu>
        {items.map((item: ProductInfo) => (
          <Item key={`${item.type}-${item.name}`} data-testid="submenu" menuWidth={menuWidth}>
            <Anchor {...item} hideSubMenu={hideSubMenu} />
          </Item>
        ))}
      </Menu>
    </DropdownMenu>
  );
};

export default DropdownMenuComponent;

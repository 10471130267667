import styled from 'styled-components';
import { IconProps, IconType } from '@t/styles';

interface Position {
  left: number;
  top: number;
}

export type IconPositions = {
  [key in IconType]: Position;
};

const iconPosition: IconPositions = {
  GITHUB_BI: { left: 4.9, top: 67 },
  FACEBOOK_BI: { left: 8.4, top: 67 },
  TWITTER_BI: { left: 12.4, top: 67 },
  MEDIUM_BI: { left: 17, top: 67 },

  CHART_BI: { left: 3.2, top: 11.9 },
  EDITOR_BI: { left: 15.7, top: 11.9 },
  GRID_BI: { left: 21.7, top: 11.9 },
  CALENDAR_BI: { left: 9.2, top: 11.9 },
  IMAGE_EDITOR_BI: { left: 27.8, top: 11.9 },

  AUTO_COMPLETE_BI: { left: 2.75, top: 30.5 },
  APP_LOADER_BI: { left: 8.8, top: 30.5 },
  COLOR_PICKER_BI: { left: 14.3, top: 30.5 },
  CONTEXT_MENU_BI: { left: 19.6, top: 30.5 },
  DATE_PICKER_BI: { left: 25.4, top: 30.5 },
  PAGINATION_BI: { left: 31.7, top: 30.5 },
  ROLLING_BI: { left: 38.6, top: 30.5 },
  SELECT_BOX_BI: { left: 45, top: 30.5 },
  TIME_PICKER_BI: { left: 52, top: 30.5 },
  TREE_BI: { left: 58.2, top: 30.5 },

  TOAST_UI_DOC_BI: { left: 2.3, top: 92 },
  ESLINT_CONFIG_BI: { left: 17.7, top: 92 },
  RELEASE_NOTE_BI: { left: 34, top: 92 },

  TOP_BUTTON_ICON: { left: 40.8, top: 12.3 },

  FIRST_PAGE_ICON: { left: 73.3, top: 24.2 },
  PREV_PAGE_ICON: { left: 76.05, top: 24.2 },
  NEXT_PAGE_ICON: { left: 73.3, top: 28.9 },
  LAST_PAGE_ICON: { left: 76.05, top: 28.9 },
  DISABLED_FIRST_PAGE_ICON: { left: 93.1, top: 24.2 },
  DISABLED_PREV_PAGE_ICON: { left: 95.85, top: 24.2 },
  DISABLED_NEXT_PAGE_ICON: { left: 93.1, top: 28.9 },
  DISABLED_LAST_PAGE_ICON: { left: 95.85, top: 28.9 },

  NHN_CLOUD_BI: { left: 3, top: 58 },
  TOAST_FILE_BI: { left: 62.3, top: 58 },
  DOORAY_BI: { left: 40.4, top: 58 },
  NCP_BI: { left: 22.6, top: 58 },

  HEADER_OPENED_ICON: { left: 60.8, top: 69.8 },
  HEADER_CLOSED_ICON: { left: 66, top: 69.8 },
  HEADER_SITEMAP_CLOSED_ICON: { left: 72.3, top: 69.8 },
  HEADER_SITEMAP_OPENED_ICON: { left: 78.1, top: 69.8 },
  SITEMAP_CLOSED_ICON: { left: 68.7, top: 80.4 },
  SITEMAP_OPENED_ICON: { left: 68.7, top: 88.2 },
  POST_TAG_MORE_ICON: { left: 72.9, top: 81.4 },
  POST_TAG_LESS_ICON: { left: 72.9, top: 87.4 },
};

export const Icon = styled.i<IconProps>`
  display: inline-block;
  background-image: url(https://uicdn.toast.com/toastui/img/toast-ui-icons-2x-20211012.png);
  background-size: 1920px;
  background-position: ${({ iconType }) => {
    return iconType && iconPosition[iconType]
      ? `${iconPosition[iconType].left}% ${iconPosition[iconType].top}%`
      : '';
  }};

  @media (max-width: 1200px) {
    background-size: 1200px;
  }

  @media (max-width: 720px) {
    background-size: 1920px;
  }
`;

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';

import { MenuItems, ProductInfo, UrlLinks } from '@t/components';
import { createProductSectionData, getScrollPosition, isKorean } from '@/util';
import { feGuideUrl, postUrl } from '@/constant';
import { LogoLink } from '@/components/common/Link';
import { Icon } from '@/components/common/Icon';
import { List } from '@/components/common/List';
import MenuItem from '@/components/layout/MenuItem';
import createGA from '@/components/common/createGA';
import SitemapSection from '@/components/main/sitemap/SitemapSection';
import { IconType } from '@t/styles';

const MENU_INFOS = [
  ['application', true],
  ['component', true],
  ['tool', true],
  ['fe guide', false],
  ['post', false],
] as const;

const urlLinks: UrlLinks = {
  'fe guide': isKorean ? feGuideUrl.KO : feGuideUrl.EN,
  post: isKorean ? postUrl.KO : postUrl.EN,
};

interface HeaderItems {
  application: ProductInfo[];
  component: ProductInfo[];
  tool: ProductInfo[];
}

interface HeaderProps {
  items: HeaderItems;
}

interface HeaderStyleProps {
  isScrolled: boolean;
  isMobileHeaderOpened: 'opened' | 'closed';
}

const Header = styled.div<HeaderStyleProps>`
  position: fixed;
  padding-left: 240px;
  top: 0;
  left: 0;
  right: 0;
  height: 69px;
  background-color: #fff;
  z-index: 9999;
  border-bottom: ${({ isScrolled }) => (isScrolled ? '1px solid #efefef' : '')};

  @media (max-width: 720px) {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px 0 20px;
    border-bottom: none;
    opacity: ${({ isMobileHeaderOpened }) => (isMobileHeaderOpened === 'opened' ? '85%' : '')};
  }
`;

const Logo = styled.h1`
  display: inline-block;
  position: absolute;
  left: 60px;
  top: 23px;
  margin: 0;
  width: 152px;
  height: 24px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  color: #fff;

  @media (max-width: 1200px) {
    left: 30px;
  }

  @media (max-width: 720px) {
    position: static;
  }
`;

const NavBar = styled.div`
  display: inline-block;
  min-width: 500px;

  @media (max-width: 720px) {
    display: none;
  }
`;

const MenuList = styled(List)`
  white-space: nowrap;
`;

const MoreButton = styled(Icon)`
  display: none;

  @media (max-width: 720px) {
    display: inline-block;
    width: 26px;
    height: 26px;
    background-size: 1000px;
  }
`;

const MobileHeaderMenu = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;

  & .sitemap-logo {
    display: none;
  }

  @media (max-width: 720px) {
    &.closed {
      position: fixed;
      top: 56px;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9999;
      padding-top: 25px;
      display: block;
      overflow-y: auto;
    }
  }
`;

const getMenuItemProps = (items: HeaderItems): MenuItems[] =>
  MENU_INFOS.map((menuInfo) => {
    const [type] = menuInfo;

    if (menuInfo[1]) {
      return {
        type,
        subMenu: items[menuInfo[0]],
      };
    }

    return {
      type,
      link: urlLinks[menuInfo[0]],
    };
  });

const HeaderComponent: React.FC<HeaderProps> = ({ items }) => {
  const { allProductsJson } = useStaticQuery(
    graphql`
      query headerQuery {
        # query for each section
        ...allProductsJson
      }
    `
  );

  const [index, setIndex] = useState<number>(-1);
  const [clicked, setClicked] = useState<boolean>(false);
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [isMobileHeaderOpened, setMobileHeaderOpened] = useState<'opened' | 'closed'>('opened');

  const showSubMenu = (ev: React.MouseEvent | React.TouchEvent): void => {
    const target = (ev.target as HTMLElement).getAttribute('data-index')
      ? ev.target
      : ev.currentTarget;

    setIndex(parseInt((target as HTMLElement).getAttribute('data-index') ?? '-1', 10));
    setClicked(false);
  };
  const hideSubMenu = (): void => {
    setIndex(-1);
    setClicked(false);
  };
  const clickSubMenu = ({ target }: React.MouseEvent): void => {
    if ((target as HTMLElement).className === 'link') {
      setClicked(true);
    }
  };
  const isShowSubMenu = (idx: number): boolean => index === idx && !clicked;
  const clickMobileButton = (): void => {
    isMobileHeaderOpened === 'opened'
      ? document.body.classList.add('closed')
      : document.body.classList.remove('closed');
    setMobileHeaderOpened(isMobileHeaderOpened === 'opened' ? 'closed' : 'opened');
  };
  const clearShowState = (): void => {
    document.body.classList.remove('closed');
    setMobileHeaderOpened('opened');
  };

  const headerMenuItems: MenuItems[] = getMenuItemProps(items);
  const ga = createGA('Header', 'click', 'header logo', 'logo');

  const sitemapData = createProductSectionData(allProductsJson);
  const mobileGa = createGA('Header - sitemap', 'click', 'sitemap');

  const content = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (content.current) {
      content.current.addEventListener('click', ({ target }) => {
        const tagName = (target as HTMLElement).tagName.toLowerCase();
        const parentTagName = (target as HTMLElement).parentElement?.tagName.toLowerCase();

        if (tagName === 'a' || parentTagName === 'a') {
          clearShowState();
        }
      });
    }

    const onScroll = (): void => setScrolled(getScrollPosition().y > 0);
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [content]);

  return (
    <>
      <Header
        isScrolled={scrolled}
        isMobileHeaderOpened={isMobileHeaderOpened}
        data-testid="header"
        onClick={clickSubMenu}
      >
        <Logo onClick={clearShowState}>
          <LogoLink url="/" ga={ga}>
            TOAST UI
          </LogoLink>
        </Logo>
        <NavBar>
          <MenuList>
            {headerMenuItems.map((menu, idx) => (
              <MenuItem
                menu={menu}
                showSubMenu={showSubMenu}
                hideSubMenu={hideSubMenu}
                isShowSubMenu={isShowSubMenu}
                index={idx}
                key={menu.type}
              />
            ))}
          </MenuList>
        </NavBar>
        <MoreButton
          onClick={clickMobileButton}
          iconType={`HEADER_${isMobileHeaderOpened.toUpperCase()}_ICON` as IconType}
        />
      </Header>
      <MobileHeaderMenu ref={content} className={isMobileHeaderOpened}>
        <SitemapSection items={sitemapData} ga={mobileGa} isHeader={true} />
      </MobileHeaderMenu>
    </>
  );
};

export default HeaderComponent;

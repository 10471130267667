const footerIconList = [
  {
    name: 'github',
    url: 'https://github.com/',
  },
  {
    name: 'facebook',
    url: 'https://www.facebook.com/NHNCloud',
  },
  {
    name: 'twitter',
    url: 'https://twitter.com/toastui',
  },
  {
    name: 'medium',
    url: 'https://medium.com/@toastui',
  },
];

const postUrl = {
  KO: '/posts/ko',
  EN: '/posts/en',
  PREFIX: '/posts',
  TYPE: 'post',
} as const;

const POST_ALL_TAG = 'All';

const feGuideUrl = {
  KO: '/fe-guide/ko',
  EN: '/fe-guide/en',
  PREFIX: '/fe-guide',
  TYPE: 'fe-guide',
} as const;

const MAX_PAD_WIDTH = 768;

const BUTTON_DISPLAY_SCROLL_POSITION_Y = 400;

export const ENG_WPM = 200;
export const CJK_CPM = 500;

export {
  footerIconList,
  postUrl,
  POST_ALL_TAG,
  feGuideUrl,
  MAX_PAD_WIDTH,
  BUTTON_DISPLAY_SCROLL_POSITION_Y,
};

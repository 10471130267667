import React from 'react';
import styled from 'styled-components';

import { MenuItems } from '@t/components';
import DropdownMenu from '@/components/layout/DropdownMenu';
import { ListItem } from '@/components/common/List';
import { Link } from '@/components/common/Link';
import createGA from '@/components/common/createGA';

const PADDING_LEFT_VALUES = [248, 366, 483];
const WIDTH_VALUES = [100, 192, 130];

interface MenuItemProps {
  menu: MenuItems;
  index: number;
  showSubMenu: (ev: React.MouseEvent | React.TouchEvent) => void;
  hideSubMenu: () => void;
  isShowSubMenu: (index: number) => boolean;
}

const Item = styled(ListItem)`
  padding: 0 7px;

  &:hover:after {
    content: ' ';
    position: relative;
    display: block;
    height: 3px;
    width: 100%;
    background: #515ce6;
    z-index: 100;
  }

  &:hover > span,
  &:hover > a span {
    color: #515ce6;
  }
`;

const Title = styled.span`
  display: inline-block;
  padding: 26px 8px;
  color: #222;

  &:hover {
    color: #515ce6;
  }
`;

const MenuTitle = styled.span`
  display: inline-block;
  padding: 26px 8px;
  color: #222;
`;

const MenuItemComponent: React.FC<MenuItemProps> = ({
  menu,
  index,
  showSubMenu,
  hideSubMenu,
  isShowSubMenu,
}) => {
  const { type, subMenu, link } = menu;
  const upperType = type.toUpperCase();
  const title = type !== 'fe guide' ? `${upperType}S` : upperType;
  const ga = createGA('Header', 'click', 'header menu', title);

  const onTouchMenu = (ev: React.TouchEvent) => {
    ev.stopPropagation();
    showSubMenu(ev);
  };

  if (subMenu) {
    return (
      <Item
        data-testid={title}
        data-index={index}
        onMouseEnter={showSubMenu}
        onMouseLeave={hideSubMenu}
        onTouchStart={onTouchMenu}
      >
        <Title>{title}</Title>
        <DropdownMenu
          type={type}
          items={subMenu}
          paddingLeft={PADDING_LEFT_VALUES[index]}
          menuWidth={WIDTH_VALUES[index]}
          isShow={isShowSubMenu(index)}
          hideSubMenu={hideSubMenu}
        />
      </Item>
    );
  }

  return (
    <Item>
      <Link type="internal" url={link ?? ''} ga={ga}>
        <MenuTitle>{title}</MenuTitle>
      </Link>
    </Item>
  );
};

export default MenuItemComponent;

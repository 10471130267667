import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { ProductInfo } from '@t/components';
import Header from '@/components/layout/Header';
import Footer from '@/components/layout/Footer';

import '@/query/productQueryFragment';

import 'normalize.css';
import '@/assets/css/common.css';
import '@/assets/css/tui-prismjs-theme.css';

interface MenuDataProps {
  edges: EdgeNode[];
}

interface EdgeNode {
  node: ProductInfo;
}

interface Menu {
  application: ProductInfo[];
  component: ProductInfo[];
  tool: ProductInfo[];
  references: ProductInfo[];
}

const getMenuData = (products: MenuDataProps) => {
  const menu: Menu = {
    application: [],
    component: [],
    tool: [],
    references: [],
  };

  products.edges.forEach(({ node }) => menu[node.type].push(node));

  return menu;
};

const Layout: React.FC = ({ children }) => {
  const { allProductsJson } = useStaticQuery(
    graphql`
      query layoutQuery {
        ...allProductsJson
      }
    `
  );

  return (
    <div>
      <Header items={getMenuData(allProductsJson)} />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;

import React from 'react';
import styled from 'styled-components';

import { footerIconList } from '@/constant';
import { InlineBlockLink } from '@/components/common/Link';
import { List, ListItem } from '@/components/common/List';
import { Icon } from '@/components/common/Icon';
import createGA from '@/components/common/createGA';

const ICONS = ['TWITTER_BI', 'FACEBOOK_BI', 'GITHUB_BI', 'MEDIUM_BI'] as const;

interface LinkInfo {
  url: string;
  title: string;
}

const Wrapper = styled.div`
  height: 80px;
  border-top: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    height: 60px;
  }

  @media (max-width: 720px) {
    height: 125px;
  }
`;

const Content = styled.div`
  max-width: 1200px;
  width: 62.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1200px) {
    max-width: 900px;
    width: 75%;
  }

  @media (max-width: 768px) {
    max-width: 700px;
    width: 91%;
  }

  @media (max-width: 720px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

const FooterLinkArea = styled.div`
  @media (max-width: 720px) {
    margin-top: 12px;
  }
`;

const Item = styled.span`
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #777;

  @media (max-width: 1200px) {
    font-size: 10px;
  }

  @media (max-width: 720px) {
    font-size: 10px;
  }
`;

const IconMenu = styled(ListItem)`
  margin: 0 13px;
`;

const IconItem = styled(Icon)`
  width: 32px;
  height: 32px;

  @media (max-width: 720px) {
    width: 25px;
    height: 25px;
    background-size: 1200px;
    opacity: 40%;
  }
`;

const LinkItem: React.FC<LinkInfo> = ({ url, title }) => {
  const ga = createGA('Footer', 'click', 'footer menu', title);

  return (
    <Item>
      <InlineBlockLink type="external" url={url} ga={ga}>
        {title}
      </InlineBlockLink>
    </Item>
  );
};

const Icons = () => {
  const ga = createGA('Footer', 'click', 'footer menu');

  return (
    <List>
      {footerIconList.map(({ name, url }, index) => (
        <IconMenu key={`link-${index}`} title={name}>
          <InlineBlockLink type="external" url={url} ga={{ ...ga, label: `footer menu - ${name}` }}>
            <IconItem iconType={ICONS[index]} />
          </InlineBlockLink>
        </IconMenu>
      ))}
    </List>
  );
};

const Footer: React.FC = () => (
  <Wrapper>
    <Content>
      <FooterLinkArea>
        <LinkItem url="https://www.toast.com" title="© NHN Cloud Corp." />
      </FooterLinkArea>
      <Icons />
    </Content>
  </Wrapper>
);

export default Footer;
